import React from "react";
import styles from "./GridView.module.css";
import { JsonValue } from "../../routes/Home";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import pattern from "../../static/pattern.svg";

export default function GridView({ images }: { images: JsonValue[] }) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [img, setImg] = React.useState<JsonValue>();

    function onClick(image?: JsonValue) {
        if (!image) {
            return;
        }
        setImg(image);
        setIsOpen(true);
    }

    function createElement(
        image?: JsonValue,
        key?: number,
        autoMuted = true,
        controls = false,
        autoplay = true
    ) {
        if (!image) {
            return null;
        }
        if (image.Image.match(/gif|webp/)?.length) {
            return (
                <img
                    key={key}
                    src={`./imgs/${image.Image}`}
                    alt={image.Caption}
                    onClick={(_) => onClick(image)}
                    loading={"lazy"}
                />
            );
        }

        return (
            <video
                src={`./imgs/${image?.Image}`}
                key={key}
                autoPlay={autoplay}
                onClick={(_) => onClick(image)}
                loop
                controls={controls}
                muted={autoMuted}
            />
        );
    }

    return (
        <>
            {modalIsOpen && (
                <Modal
                    onClose={() => setIsOpen(false)}
                    open={modalIsOpen}
                    center
                    showCloseIcon={false}
                    classNames={{
                        modal: styles.modal,
                    }}
                >
                    <figure className={styles.modalInner}>
                        <div
                            className={styles.image}
                            style={{
                                background: `#efefef url(${pattern})`,
                            }}
                        >
                            {createElement(img, -1, false, true, false)}
                        </div>

                        <figcaption className={styles.modalContent}>
                            {img?.Caption}

                            <ul className={styles.tags}>
                                {img?.Hashtags?.split(" ").map((tag) => (
                                    <li key={tag}>{tag}</li>
                                ))}
                            </ul>
                        </figcaption>
                    </figure>
                </Modal>
            )}

            <div className={styles.grid}>
                {images.map((image: JsonValue, index: number) => {
                    let src = image.Image;
                    if (image.Image.endsWith("webm")) {
                        src = image.Image.replace(".webm", "-preview.webp");
                    }
                    return (
                        <img
                            key={index}
                            src={`./imgs/${src}`}
                            alt={image.Caption}
                            onClick={(_) => onClick(image)}
                            loading={"lazy"}
                        />
                    );
                })}
            </div>
        </>
    );
}
