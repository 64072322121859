import React from "react";
import styles from "./Footer.module.css";
import arrow from "../../static/arrow.svg";

export default function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.left}>
                <a
                    href="https://leapgroupnetwork.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={arrow} alt="" />
                    Go to Main Site
                </a>
            </div>

            <div className={styles.right}>
                <ul className={styles.socialList}>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.linkedin.com/company/leap-group/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </li>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.instagram.com/leapgroupnetwork/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Instagram
                        </a>
                    </li>
                    <li className={styles.socialItem}>
                        <a
                            href="https://twitter.com/_LEAP_Group"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Twitter
                        </a>
                    </li>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.facebook.com/leapgroupnetwork"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Facebook
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
