import React from "react";
import logo from "../../static/leap-logos_group-green.svg";
import mlmm from "../../static/market-less-matter-more.svg";
import styles from "./Navbar.module.css";
import clsx from "clsx";
import shuffleicon from "../../static/shuffle.svg";
import gridicon from "../../static/grid.svg";

export default function Navbar({
    animating,
    shuffle,
    setShuffle,
}: {
    animating: boolean;
    shuffle: boolean;
    setShuffle: (state: boolean) => void;
}) {
    return (
        <div className={styles.navbar}>
            <div className={styles.left}>
                <img src={logo} className={styles.logo} alt="logo" />
            </div>

            <div className={styles.center}>
                <div className={styles.switches}>
                    <button
                        className={clsx({
                            [styles.switch]: true,
                            [styles.active]: shuffle,
                        })}
                        onClick={() => setShuffle(true)}
                    >
                        <img src={shuffleicon} alt="shuffle mode" width={17} />
                        Shuffle
                    </button>
                    <button
                        className={clsx({
                            [styles.switch]: true,
                            [styles.active]: !shuffle,
                        })}
                        onClick={() => setShuffle(false)}
                    >
                        <img src={gridicon} alt="grid mode" width={17} />
                        Grid (Analog)
                    </button>
                </div>
            </div>

            <img
                src={mlmm}
                className={clsx({
                    [styles.rotating]: animating,
                    [styles.mlmm]: true,
                })}
                alt="logo"
            />
        </div>
    );
}
