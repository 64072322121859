import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./Home.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Figure from "../components/Figure";
import audioEnabledIcon from "../static/audio.svg";
import audioDisabledIcon from "../static/audio-mute.svg";
import ConfettiExplosion from "react-confetti-explosion";
import clsx from "clsx";
import GridView from "../components/GridView";

export type JsonValue = {
    Name?: string;
    Slug?: string;
    "Collection ID"?: string;
    "Item ID"?: string;
    "Created On"?: string;
    "Updated On"?: string;
    "Published On"?: string;
    Image: string;
    Caption?: string;
    Hashtags?: string;
};

const audio = new Audio("./shuffle.mp4");
const duration = 1000;

function randomImage(json: any): JsonValue {
    let randomIndex = Math.floor(Math.random() * json.length);
    // check that the element exists on json
    if (!json[randomIndex]) {
        randomIndex = 1;
    }
    return json[randomIndex];
}

export default function Home() {
    const [isExploding, setIsExploding] = useState(false);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [animating, setAnimating] = useState(false);
    const [shuffle, setShuffle] = useState(true);
    const [img, setImg] = useState<JsonValue>({
        Image: "./random.webp",
    });
    const [json, setJson] = useState<JsonValue[]>([]);
    const [initialLoad, setInitialLoad] = useState(true);

    // Store the json in state on the components first render: instructed by the empty array passed as deps
    useEffect(() => {
        fetch("./data.json")
            .then((response) => response.json())
            .then((json) => {
                setJson(json);
            });
    }, []);

    // When the json or animating state changes, update the image
    useLayoutEffect(() => {
        if (animating) {
            setImg({
                Image: "./random.webp",
            });
            return;
        }

        setImg(randomImage(json));
    }, [json, animating]);

    async function handleClick(e: any) {
        // Don't want to animate if we're in grid mode
        if (!shuffle) {
            return;
        }

        // Once a user interacts, we don't want to show the initial text again.
        setInitialLoad(false);

        // If the element or its parent is an anchor or button, then don't do anything
        const els = ["A", "BUTTON"];
        if (
            els.includes(e.target.tagName) ||
            els.includes(e.target.parentElement.tagName)
        ) {
            return;
        }

        // Prevent spamming clicks, i.e clicking multiple times before the animation is complete
        if (animating) {
            return;
        }

        // If audio is already playing, stop it
        if (audioEnabled && !audio.paused) {
            audio.pause();
            audio.currentTime = 0;
        }

        if (audioEnabled) {
            await audio.play();
        }

        setAnimating(true);
        setIsExploding(false);

        setTimeout(() => {
            setAnimating(false);
            setIsExploding(true);
            if (audioEnabled) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, duration);
    }

    function toggleAudio(e: any) {
        setAudioEnabled(!audioEnabled);
    }

    return (
        <div className={styles.container} onClick={handleClick}>
            <Navbar
                animating={animating}
                shuffle={shuffle}
                setShuffle={setShuffle}
            />

            <div className={styles.main}>
                {isExploding && (
                    <ConfettiExplosion
                        style={{ transform: "translateY(35px)" }}
                        zIndex={1}
                        colors={[
                            "#01AD4B",
                            "#01AED6",
                            "#c016a2",
                            "#F36E21",
                            "#f6be00",
                        ]}
                    />
                )}

                {shuffle && img && !initialLoad && (
                    <Figure animating={animating} data={img} />
                )}

                {!shuffle && !initialLoad && <GridView images={json} />}

                {initialLoad && (
                    <>
                        <p>
                            We like to ask questions. Lots of them. Of you. Your
                            audience. And ourselves. Why? Because we’re curious
                            people, who are curious about people. Because we’ve
                            seen the exponential power humans have. To solve
                            problems. To erase barriers. To connect through real
                            human experiences, made for real humans.
                        </p>
                        <p>
                            Everything we do, from the seeds of our research to
                            our anything-but-garden-variety creative, is rooted
                            with that human connection. Because making
                            breakthrough connections is the only way your
                            business can thrive and grow. Who knew? We knew. We
                            know. We can help you know it too. All you’ve got to
                            do is ask.
                        </p>
                    </>
                )}

                {shuffle && (
                    <div className={styles.directions}>
                        <p>( CLICK ANYWHERE TO LEAP )</p>

                        <button
                            className={clsx({
                                [styles.audioToggle]: true,
                                "no-trigger": true,
                            })}
                            onClick={toggleAudio}
                        >
                            Sound {audioEnabled ? "On" : "Off"}{" "}
                            <img
                                src={
                                    audioEnabled
                                        ? audioEnabledIcon
                                        : audioDisabledIcon
                                }
                                className={styles.audio}
                                alt="toggle audio"
                            />
                        </button>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}
