import styles from "./Figure.module.css";
import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { JsonValue } from "../../routes/Home";

export default function Figure({
    animating,
    data,
}: {
    animating: boolean;
    data: JsonValue;
}) {
    function createElement(image?: JsonValue, autoMuted = true) {
        if (!image) {
            return null;
        }
        if (image.Image.match(/gif|webp/)?.length) {
            return (
                <img
                    src={`./imgs/${image.Image}`}
                    alt={image.Caption}
                    loading={"lazy"}
                />
            );
        }

        return (
            <video
                src={`./imgs/${image?.Image}`}
                controls={true}
                loop
                muted={autoMuted}
            />
        );
    }
    return (
        <>
            <motion.figure
                layout
                className={clsx({
                    [styles.figure]: true,
                })}
            >
                {!animating && data.Caption && (
                    <motion.div
                        className={clsx({
                            [styles.figcaption]: true,
                        })}
                    >
                        <figcaption>
                            <p>{data.Caption}</p>

                            <ul className={styles.tags}>
                                {data.Hashtags?.split(" ").map((tag) => (
                                    <li key={tag}>{tag}</li>
                                ))}
                            </ul>
                        </figcaption>
                    </motion.div>
                )}
                <div
                    className={clsx({
                        [styles.img]: true,
                        [styles.animating]: animating,
                    })}
                >
                    {createElement(data, false)}
                </div>
            </motion.figure>
        </>
    );
}
